import { useEffect, useState } from 'react'
import styles from '../Location/BidLocationFilter/BidLocationState/BidLocationState.module.scss'
import getArrQuery from '../../../../../utilities/functions/getArrQuery'

export default function BidPickupFilter({
  pickupDate,
  activePickupDates,
  addQuery,
  reduceQuery,
}: any) {
  const [isChecked, setIsChecked] = useState<boolean>(
    getArrQuery(activePickupDates).includes(pickupDate)
  )

  useEffect(() => {
    isChecked
      ? addQuery({ key: 'pickupDate', value: pickupDate })
      : reduceQuery({ key: 'pickupDate', value: pickupDate })
  }, [isChecked])

  useEffect(() => {
    const isActiveQuery = getArrQuery(activePickupDates).includes(pickupDate)
    if (isActiveQuery !== isChecked) {
      setIsChecked(isActiveQuery)
    }
  }, [activePickupDates])

  const handleCheckClick = () => {
    setIsChecked(!isChecked)
  }

  return (
    <li className="font-normal text-xs mb-2.5 list-none">
      <label
        htmlFor={pickupDate}
        className={`${styles.check__label} flex relative items-center justify-start`}
      >
        <button
          onClick={handleCheckClick}
          className={'text-left flex flex-row items-center'}
        >
          <div
            className="checkbox cursor-pointer inline-block flex-none"
            role="button"
            onKeyDown={(e: any) => e.key === 'Enter' && handleCheckClick()}
            tabIndex={0}
          >
            {isChecked && (
              <svg
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                fill="#297AF7"
              >
                <g>
                  <polygon
                    className="st0"
                    points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "
                  />
                </g>
              </svg>
            )}
          </div>
          <span className="pl-6 inline-block" onClick={handleCheckClick}>
            {pickupDate}
          </span>
        </button>
      </label>
    </li>
  )
}
